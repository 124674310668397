<template>
  <input
    type="number"
    :class="className"
    :value="modelValue"
    :min="minValue"
    :max="maxValue"
    :step="interval"
    v-on:[changeEventStrategy]="handleChange"
  />
</template>

<script>
import { defineComponent, toRefs } from 'vue';

import numberUtils from '@/utils/number.utils';

const CHANGE_EVENT_STRATEGIES = ['input', 'change'];

export default defineComponent({
  emits: ['update:modelValue'],
  props: {
    modelValue: {
      type: Number,
      required: true,
    },
    minValue: {
      type: Number,
      required: false,
      default: 0,
    },
    maxValue: {
      type: Number,
      required: false,
      default: 100,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
    interval: {
      type: Number,
      required: false,
      default: 1,
    },
    useDecimal: {
      type: Boolean,
      required: false,
    },
    changeEventStrategy: {
      type: String,
      required: false,
      validator: (value) => CHANGE_EVENT_STRATEGIES.includes(value),
      default: 'change',
    },
    className: {
      type: [String, Object],
      required: false,
    },
  },
  setup(props, { emit }) {
    const { minValue, maxValue, useDecimal } = toRefs(props);

    const handleChange = (event) => {
      const { value: v } = event.target;

      const value = useDecimal.value ? parseFloat(v) : parseInt(v, 10);

      emit(
        'update:modelValue',
        numberUtils.restrictedNumber(value, minValue.value, maxValue.value),
      );
    };

    return {
      handleChange,
    };
  },
});
</script>
