<script>
import { defineComponent, toRefs } from 'vue';
import VueSlider from 'vue-slider-component';

import numberUtils from '@/utils/number.utils';

const TOOLTIP_PLACEMENTS = ['top', 'right', 'left', 'bottom'];

export default defineComponent({
  emits: ['update:modelValue'],
  components: {
    VueSlider,
  },
  props: {
    modelValue: {
      type: Number,
      required: true,
    },
    minValue: {
      type: Number,
      required: false,
      default: 0,
    },
    maxValue: {
      type: Number,
      required: false,
      default: 100,
    },
    interval: {
      type: Number,
      required: false,
      default: 1,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
    tooltipPlacement: {
      type: String,
      required: false,
      default: 'top',
      validator: (value) => TOOLTIP_PLACEMENTS.includes(value),
    },
    className: {
      type: [String, Object],
      required: false,
    },
  },
  setup(props, { emit }) {
    const {
      minValue,
      maxValue,
    } = toRefs(props);

    const handleChange = (value) => emit('update:modelValue', numberUtils.restrictedNumber(value, minValue.value, maxValue.value));

    return {
      handleChange,
    };
  },
  render() {
    return (
      <VueSlider
        class={this.className}
        modelValue={this.modelValue}
        min={this.minValue}
        max={this.maxValue}
        interval={this.interval}
        disabled={this.disabled}
        tooltipPlacement={this.tooltipPlacement}
        onChange={this.handleChange}
      />
    );
  },
});
</script>

<style lang="scss">
@import "@/assets/styles/common/variables.scss";

$themeColor: $dark-green;

@import "~vue-slider-component/lib/theme/antd.scss";
</style>
