<script>
import { defineComponent } from 'vue';

import InputSlider from '@/components/common/InputSlider.vue';
import InputNumber from '@/components/common/InputNumber.vue';

const InputNumberSlider = defineComponent({
  components: {
    InputNumber,
    InputSlider,
  },
  props: {
    modelValue: {
      type: Number,
      required: true,
    },
    minValue: {
      type: Number,
      required: false,
    },
    maxValue: {
      type: Number,
      required: false,
    },
  },
  emits: ['update:modelValue'],
  setup(_, { emit }) {
    const handleValueUpdate = (value) => {
      emit('update:modelValue', value);
    };

    return {
      handleValueUpdate,
    };
  },
  render() {
    return (
      <div class={this.$style.percentageWrapper}>
        <InputSlider
          className={this.$style.percentageSlider}
          modelValue={this.modelValue}
          minValue={this.minValue}
          maxValue={this.maxValue}
          onUpdate:modelValue={this.handleValueUpdate}
        />
        <InputNumber
          className={this.$style.percentageNumber}
          modelValue={this.modelValue}
          minValue={this.minValue}
          maxValue={this.maxValue}
          onUpdate:modelValue={this.handleValueUpdate}
        />
      </div>
    );
  },
});

export default InputNumberSlider;
</script>

<style lang="scss" module>
.percentageWrapper {
  display: flex;
  align-items: center;
}

.percentageNumber {
  width: calc(20% - 0.5rem);
}

.percentageSlider {
  width: calc(80% - 0.5rem) !important;
  margin-right: 2rem;
  margin-left: 0.8rem;
}
</style>
