<template>
  <div>
    <span v-if="leftText" class="mr-1">{{ leftText }}</span>
    <VueToggle
      class="custom-toggle"
      :modelValue="modelValue"
      @change="$emit('update:modelValue', $event)"
    />
    <span v-if="rightText" class="ml-1">{{ rightText }}</span>
  </div>
</template>

<script>
import VueToggle from '@vueform/toggle';

export default {
  name: 'Toggle',
  components: { VueToggle },
  emits: ['update:modelValue'],
  props: {
    modelValue: Boolean,
    leftText: {
      type: String,
      required: false,
    },
    rightText: {
      type: String,
      required: false,
    },
  },
};
</script>

<style lang="scss" scoped>
// Override basic styles
// Library is based on 1.6rem font-size
.custom-toggle {
  --toggle-width: calc(3rem * 1.6);
  --toggle-height: calc(1.25rem * 1.6);
  --toggle-border: calc(0.125rem * 1.6);
  --toggle-font-size: calc(0.75rem * 1.6);
  --toggle-duration: 150ms;
  --toggle-bg-on: #00474d;
  --toggle-bg-off: #e5e7eb;
  --toggle-bg-on-disabled: #d1d5db;
  --toggle-bg-off-disabled: #e5e7eb;
  --toggle-border-on: #00474d;
  --toggle-border-off: #e5e7eb;
  --toggle-border-on-disabled: #d1d5db;
  --toggle-border-off-disabled: #e5e7eb;
  --toggle-ring-width: 3px;
  --toggle-ring-color: #00474d30;
  --toggle-text-on: #ffffff;
  --toggle-text-off: #374151;
  --toggle-text-on-disabled: #9ca3af;
  --toggle-text-off-disabled: #9ca3af;
  --toggle-handle-enabled: #ffffff;
  --toggle-handle-disabled: #f3f4f6;
}
</style>
