<script>
import { defineComponent, ref, computed, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import Modal from '@/components/common/Modal.vue';
import Button from '@/components/common/Button.vue';
import Choices from '@/components/common/Choices.vue';
import InputNumberSlider from '@/components/common/InputNumberSlider.vue';
import Toggle from '@/components/common/Toggle.vue';
import loyaltyCardTypesConstants from '@/constants/loyaltyCardTypes.constants';

import LoyaltyCardChoices from './LoyaltyCardChoices.vue';

const MAX_VALUE_VISIT_COUNT = 20;
const MAX_VALUE_AMOUNT = 1000;

const MIN_DISCOUNT_VALUE = 1;
const MAX_DISCOUNT_VALUE = 200;

const SetUpLoyaltyCardModal = defineComponent({
  components: {
    Modal,
    Button,
    Choices,
    InputNumberSlider,
    LoyaltyCardChoices,
    Toggle,
  },
  emits: ['close', 'update'],
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
  },
  setup(_, { emit }) {
    const { t } = useI18n();

    const cardType = ref(null);
    const valueToReach = ref(0);
    const discountValue = ref(5);
    const useGift = ref(false);
    const gift = ref(null);

    const maxValueToReach = computed(() =>
      cardType.value === loyaltyCardTypesConstants.AMOUNT
        ? MAX_VALUE_AMOUNT
        : MAX_VALUE_VISIT_COUNT,
    );

    const canSubmit = computed(() => {
      if (!cardType.value) {
        return false;
      }

      return !Number.isNaN(valueToReach.value) && !Number.isNaN(discountValue.value);
    });

    const legendTexts = computed(() => {
      let texts;

      if (!useGift.value && cardType.value === loyaltyCardTypesConstants.AMOUNT) {
        texts = [
          t('components.setUpLoyaltyCardModal.discount.valueToReach'),
          t('components.setUpLoyaltyCardModal.discount.amountDiscountValue'),
        ];
      }

      if (!useGift.value && cardType.value === loyaltyCardTypesConstants.VISIT_COUNT) {
        texts = [
          t('components.setUpLoyaltyCardModal.discount.visitCountToReach'),
          t('components.setUpLoyaltyCardModal.discount.visitCountDiscountValue', [valueToReach.value]),
        ];
      }

      if (useGift.value && cardType.value === loyaltyCardTypesConstants.AMOUNT) {
        texts = [
          t('components.setUpLoyaltyCardModal.gift.valueToReach'),
          t('components.setUpLoyaltyCardModal.gift.amountDiscountValue'),
        ];
      }

      if (useGift.value && cardType.value === loyaltyCardTypesConstants.VISIT_COUNT) {
        texts = [
          t('components.setUpLoyaltyCardModal.gift.visitCountToReach'),
          t('components.setUpLoyaltyCardModal.gift.visitCountDiscountValue', [valueToReach.value]),
        ];
      }

      return texts;
    });

    watch(useGift, (useGiftValue) => {
      discountValue.value = useGiftValue ? 0 : 5;
    });

    const handleSubmit = () => {
      emit('update', {
        loyaltyCardTypeId: cardType.value,
        discountValue: discountValue.value,
        valueToReach: valueToReach.value,
        gift: gift.value || null,
      });
    };

    return {
      t,
      cardType,
      valueToReach,
      discountValue,
      maxValueToReach,
      canSubmit,
      handleSubmit,
      useGift,
      gift,
      legendTexts,
    };
  },
  render() {
    return (
      <Modal
        isDisplayed={this.visible}
        title={this.t('components.setUpLoyaltyCardModal.title')}
        onCloseModal={() => this.$emit('close')}
      >
        <div class={this.$style.contentWrapper}>
          <LoyaltyCardChoices vModel={this.cardType} />

          {!!this.cardType && (
            <>
              <fieldset class={[this.$style.inputControl, 'mt-2']}>
                <legend class={this.$style.inputLabel}>
                  {this.legendTexts[0]}
                </legend>
                <InputNumberSlider
                  key="valueToReach"
                  vModel={this.valueToReach}
                  maxValue={this.maxValueToReach}
                />
              </fieldset>
              <Toggle
                className="mt-2"
                vModel={this.useGift}
                leftText={this.t('components.setUpLoyaltyCardModal.useDiscount')}
                rightText={this.t('components.setUpLoyaltyCardModal.useGift')}
              />
              <fieldset class={[this.$style.inputControl, 'mt-2']}>
                <legend class={this.$style.inputLabel}>
                  {this.legendTexts[1]}
                </legend>
                {this.useGift && (
                    <input className="lowco-textbox mt-1" vModel={this.gift} />
                )}
                {!this.useGift && (
                  <InputNumberSlider
                    key="discountValue"
                    vModel={this.discountValue}
                    minValue={MIN_DISCOUNT_VALUE}
                    maxValue={MAX_DISCOUNT_VALUE}
                  />
                )}
              </fieldset>
            </>
          )}

          <Button
            class="tar mt-3"
            disabled={!this.canSubmit}
            onButtonClick={() => this.handleSubmit()}
          >
            {this.t('common.confirm')}
          </Button>
        </div>
      </Modal>
    );
  },
});

export default SetUpLoyaltyCardModal;
</script>

<style lang="scss" module>
@import '@/assets/styles/common/variables';
@import '@/assets/styles/common/mixins';

.contentWrapper {
  min-width: 30rem;

  @include xs {
    min-width: 50rem;
  }
}

.inputControl {
  border: 1px solid $dark-green;
  border-radius: 5px;
}

.inputLabel {
  padding: 0.2rem 1rem;
  background-color: $dark-green;
  color: #fff;
}
</style>
