<script>
import { defineComponent, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { useToast } from 'vue-toastification';

import useCurrentCompany from '@/composables/useCurrentCompany';
import Layout from '@/components/common/Layout.vue';
import Button from '@/components/common/Button.vue';
import SetUpLoyaltyCardModal from '@/components/offers/SetUpLoyaltyCardModal.vue';
import companyOffersService from '@/services/companyOffers.service';
import routesConstants from '@/constants/routes.constants';
import companyOffersConstants from '@/constants/companyOffers.constants';
import useCompanyOffers from '@/composables/useCompanyOffers';

const SubscriptionSuccess = defineComponent({
  components: {
    Button,
    Layout,
    SetUpLoyaltyCardModal,
  },
  setup() {
    const router = useRouter();
    const { t } = useI18n();
    const toast = useToast();

    const showCardSetUp = ref(false);

    const { currentCompanyId } = useCurrentCompany();
    const { fetchAll } = useCompanyOffers();

    const setLoyaltyCardOptions = async (options) => {
      try {
        await companyOffersService.updateCompanyLoyaltyCardType(currentCompanyId.value, options);
        fetchAll(currentCompanyId.value);

        router.push(`${routesConstants.OFFERS}?type=${companyOffersConstants.LOYALTY_CARD}`);
      } catch (err) {
        const [error] = err;

        toast.error(t(error));
      }
    }

    return {
      t,
      showCardSetUp,
      setLoyaltyCardOptions,
      // eslint-disable-next-line global-require
      subscriptionSuccessImage: require('../../assets/images/subscription_success.svg'),
    };
  },
  render() {
    return (
      <Layout>
        <div class={this.$style.container}>
          <h1 class={[this.$style.title, 'mb-2']}>{this.t('pages.subscriptionSuccess.title')}</h1>
          <h3 class="tac mb-2">{this.t('pages.subscriptionSuccess.subtitle')}</h3>

          <div class={this.$style.illustrationWrapper}>
            <img class={this.$style.illustration} src={this.subscriptionSuccessImage} alt="" />
          </div>

          <p class="mt-2 tac" vHtml={this.t('pages.subscriptionSuccess.description')} />

          <Button
            class="mt-4 tac"
            onClick={() => {
              this.showCardSetUp = true;
            }}
          >
            {this.t('pages.subscriptionSuccess.setUpLoyaltyCardButton')}
          </Button>

          <SetUpLoyaltyCardModal
            visible={this.showCardSetUp}
            onClose={() => {
              this.showCardSetUp = false;
            }}
            onUpdate={this.setLoyaltyCardOptions}
          />
        </div>
      </Layout>
    );
  },
});

export default SubscriptionSuccess;
</script>

<style lang="scss" module>
@import '@/assets/styles/common/variables';
@import '@/assets/styles/common/mixins';

.container {
  padding: 2rem;
  max-width: 120rem;
  margin: 0 auto;

  padding-bottom: 8rem;

  @include xs {
    padding: 2rem 4rem;
  }
}

.title {
  text-align: center;
  font-size: 2.4rem;

  @include ipad {
    font-size: 3rem;
  }
}

.illustrationWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.illustration {
  margin-top: 2rem;

  max-width: 40rem;
  width: 100%;
  height: auto;

  padding: 2rem;
}
</style>
