<script>
import { defineComponent } from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';

/** 
  options: [
    {
      value: String,
      labelTitle: String,
      labelDescription: String,
    }
  ]
*/

const Choices = defineComponent({
  components: {
    FontAwesomeIcon,
  },
  props: {
    modelValue: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    defaultValue: {
      type: String,
      required: false,
    },
    containerClassname: {
      type: String,
      required: false,
    },
    elementClassname: {
      type: String,
      required: false,
    },
  },
  emits: ['update:modelValue'],
  setup(_, { emit }) {
    const handleChange = (newValue) => {
      emit('update:modelValue', newValue);
    };

    const handleKeyup = (event, newValue) => {
      if (event.keyCode === 13) {
        handleChange(newValue);
      }
    };

    return {
      handleChange,
      handleKeyup,
    };
  },
  render() {
    return (
      <div role="radiogroup" tabindex={0} class={this.containerClassname}>
        {this.options.map((opt) => (
          <div
            class={this.elementClassname}
            key={opt.value}
            role="radio"
            aria-checked={opt.value === this.modelValue}
            aria-label={opt.labelTitle}
            tabindex={0}
            onKeyup={(e) => this.handleKeyup(e, opt.value)}
          >
            <input
              class={this.$style.radioButton}
              id={opt.value}
              type="radio"
              value={opt.value}
              name={this.name}
              defaultChecked={opt.value === this.modelValue}
              onChange={() => this.handleChange(opt.value)}
            />

            <label
              for={opt.value}
              class={[
                this.$style.labelWrapper,
                { [this.$style.checked]: opt.value === this.modelValue },
              ]}
            >
              <div class={this.$style.status}>
                {opt.value === this.modelValue && (
                  <FontAwesomeIcon icon={faCheck} class={this.$style.checkIcon} />
                )}
              </div>

              <div class={this.$style.content}>
                <p class={this.$style.label}>{opt.labelTitle}</p>
                <p class={this.$style.description} vHtml={opt.labelDescription} />
              </div>
            </label>
          </div>
        ))}
      </div>
    );
  },
});

export default Choices;
</script>

<style lang="scss" module>
@import '@/assets/styles/common/variables';
@import '@/assets/styles/common/mixins';

.radioButton {
  display: none;
}

.labelWrapper {
  height: 100%;

  position: relative;

  border: 3px solid $gray;
  border-radius: 6px;
  padding: 1rem 2rem;

  display: flex;
  align-items: center;

  cursor: pointer;
  user-select: none;

  backface-visibility: hidden;

  transition: transform 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;

  .status {
    position: absolute;
    right: -1rem;
    top: -1rem;
    margin-right: 0;

    min-width: 3rem;
    min-height: 3rem;
    background-color: $gray;
    border-radius: 50%;

    display: flex;
    justify-content: center;
    align-items: center;

    @include ipad {
      position: initial;
      margin-right: 2rem;
    }
  }

  .label {
    font-size: 1.8rem;
    font-weight: bold;
  }
}

.checkIcon {
  @keyframes spin-half {
    from {
      opacity: 0;
      transform: rotate(-180deg);
    }

    to {
      opacity: 1;
      transform: rotate(0deg);
    }
  }

  animation: spin-half 0.3s;
}

.labelWrapper.checked {
  background-color: $dark-green;
  border-color: $dark-green;

  transform: scale(1.048);

  .status {
    background-color: $white;
  }

  .label,
  .content,
  .description {
    color: $white;
  }
}
</style>
