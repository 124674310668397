<script>
import { defineProps, defineEmits } from 'vue';
import { useI18n } from 'vue-i18n';

import Choices from '@/components/common/Choices.vue';
import loyaltyCardTypesConstants from '@/constants/loyaltyCardTypes.constants';

const loyaltyTypes = [loyaltyCardTypesConstants.AMOUNT, loyaltyCardTypesConstants.VISIT_COUNT];
</script>

<script setup>
defineProps({
  modelValue: {
    type: String,
    required: true,
    validator: (value) => loyaltyTypes.includes(value) || value == null,
  },
});

defineEmits(['update:modelValue']);

const { t } = useI18n();

const choiceOptions = [
  {
    value: loyaltyCardTypesConstants.AMOUNT,
    labelTitle: t(
      `components.loyaltyCardChoices.${loyaltyCardTypesConstants.AMOUNT}.title`,
    ),
    labelDescription: t(
      `components.loyaltyCardChoices.${loyaltyCardTypesConstants.AMOUNT}.description`,
    ),
  },
  {
    value: loyaltyCardTypesConstants.VISIT_COUNT,
    labelTitle: t(
      `components.loyaltyCardChoices.${loyaltyCardTypesConstants.VISIT_COUNT}.title`,
    ),
    labelDescription: t(
      `components.loyaltyCardChoices.${loyaltyCardTypesConstants.VISIT_COUNT}.description`,
    ),
  },
];
</script>

<template>
  <Choices
    :modelValue="modelValue"
    :options="choiceOptions"
    name="loyaltyCardType"
    :containerClassname="$style.cardTypes"
    @update:modelValue="$emit('update:modelValue', $event)"
  />
</template>

<style lang="scss" module>
@import '@/assets/styles/common/variables';
@import '@/assets/styles/common/mixins';

.cardTypes {
  padding: 0 1.4rem;
  @include spacing-children('vertical', 2rem);

  margin-bottom: 4rem;
}
</style>
