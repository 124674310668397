const restrictedNumber = (value, min, max) => {
  if (Number.isNaN(value)) {
    return min;
  }

  if (value < min) {
    return min;
  }

  if (value > max) {
    return max;
  }

  return value;
};

export default {
  restrictedNumber,
};
